<template>
  <div
    id="osk-widget-cloudflare-turnstile"
    class="osk-widget-cloudflare-turnstile"
  />
</template>

<script setup lang="ts">
const widgetCloudflareTurnstileScriptId = 'widget-cloudflare-turnstile-script-id';

const config = useRuntimeConfig();
const {
  turnstileWidgetId,
  turnstileToken,
} = useWidgetCloudflareTurnstile();
const { isInternational } = useInternational();

function renderTurnstileWidget() {
  if (turnstileWidgetId.value) {
    turnstile.reset(turnstileWidgetId.value);
  }

  turnstileWidgetId.value = turnstile.render('#osk-widget-cloudflare-turnstile', {
    sitekey: config.public.TURNSTILE_SITE_KEY,
    size: 'flexible',
    language: isInternational ? 'en' : 'ru',
    callback: (token) => turnstileToken.value = token,
  });
}

onMounted(() => {
  if (!document.getElementById(widgetCloudflareTurnstileScriptId)) {
    const script = document.createElement('script');

    script.setAttribute('id', widgetCloudflareTurnstileScriptId);
    script.setAttribute('async', '');
    script.setAttribute('defer', '');
    script.setAttribute('src', config.public.TURNSTILE_SCRIPT_URL);
    document.head.appendChild(script);

    script.addEventListener('load', () => {
      renderTurnstileWidget();
    });
  } else {
    renderTurnstileWidget();
  }
});

onBeforeUnmount(() => {
  turnstileToken.value = null;
});
</script>

<style lang="scss">
.osk-widget-cloudflare-turnstile {
  margin-top: var(--indent-compact);
}
</style>
