<template>
  <div class="osk-footer-seo">
    <div class="osk-wrapper">
      <div class="osk-footer-seo__links">
        <span class="osk-footer-seo__span">{{ $t('components.footer.footerSeo.title') }}</span>

        <nuxt-link
          v-for="link in links"
          :key="link.path"
          class="osk-footer-seo__link"
          :to="link.path"
        >
          {{ link.label }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { parentCategoryWomenName } = useCategories();

const brands = [
  {
    label: 'Chrome Hearts',
    path: '/chrome-hearts',
  },
  {
    label: 'Van Cleef Arpels',
    path: '/vancleef-arpels',
  },
  {
    label: 'Goyard',
    path: '/goyard',
  },
  {
    label: 'JACQUEMUS',
    path: '/jacquemus',
  },
  {
    label: 'Cartier',
    path: '/cartier',
  },
  {
    label: 'LONGCHAMP',
    path: '/longchamp',
  },
  {
    label: 'Manolo Blahnik',
    path: '/manolo-blahnik',
  },
  {
    label: 'Alexander Arutyunov',
    path: '/alexanderarutyunov',
  },
  {
    label: 'Sporty Rich',
    path: '/sportyandrich',
  },
];

const links = computed(() => brands.map((brand) => ({
  label: brand.label,
  path: `/brands/${parentCategoryWomenName.value}${brand.path}`,
})));
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-footer-seo {
  @include font-style($font-size-caption, 19px, $font-weight-medium);
  padding-top: 24px;

  @include media-query(lg-and-up) {
    padding: 24px 0;
    border-bottom: 1px solid #868686;
  }

  &__links {
    border-bottom: 0.5px solid #fff;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;

    @include media-query(lg-and-up) {
      border-bottom: 0;
      padding-bottom: 0;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__span {
    font-weight: $font-weight-bold;

    @include media-query(lg-and-up) {
      font-weight: $font-weight-semibold;
      margin-right: 6px;
    }
  }

  &__link {
    margin: 4px 0;
    color: #B6B6B6;
    white-space: nowrap;

    @include media-query(lg-and-up) {
      margin: 0;
      padding-right: 6px;
      margin-right: 6px;

      &:hover {
        text-decoration: underline;
      }

      &:not(:last-child) {
        position: relative;

        &:after {
          content: '';
          width: 1px;
          height: 12px;
          background: #B6B6B6;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
