<template>
  <div class="osk-footer-bottom">
    <div class="osk-wrapper">
      <div class="osk-footer-bottom__images">
        <img
          class="osk-footer-bottom__image osk-footer-bottom__image_visa"
          src="~assets/img/visa.webp"
          alt="visa"
        >
        <img
          class="osk-footer-bottom__image osk-footer-bottom__image_mc"
          src="~assets/img/mastercard-icon.svg"
          alt="mastercard"
        >
        <template v-if="!isInternational">
          <img
            class="osk-footer-bottom__image osk-footer-bottom__image_mir"
            src="~assets/img/mir-card-icon.svg"
            alt="МИР"
          >
          <a
            href="https://sk.ru"
            target="_blank"
          >
            <img
              class="osk-footer-bottom__image osk-footer-bottom__image_sk"
              src="~assets/img/sk.webp"
              alt="sk"
            >
          </a>
        </template>
      </div>

      <div class="osk-footer-bottom__links">
        <a
          class="osk-footer-bottom__link"
          href="/info/pdf/pravila-prodazh.pdf"
          target="_blank"
        >{{ $t('components.footer.footerBottom.rules') }}</a>
        <a
          class="osk-footer-bottom__link"
          href="/info/oferta"
        >{{ $t('common.offer') }}</a>
        <a
          class="osk-footer-bottom__link"
          href="/info/pdf/politika-konfidencialnosti.pdf"
          target="_blank"
        >{{ $t('components.footer.footerBottom.privacyPolicy') }}</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { isInternational } = useInternational();
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-footer-bottom {
  background: $white;
  padding-top: $indent-large;
  padding-bottom: 150px;

  @include media-query(lg-and-up) {
    padding-bottom: $indent-large;
  }

  .osk-wrapper {
    @include media-query(lg-and-up) {
      display: flex;
      align-items: center;
    }
  }

  &__images {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 375px;
    margin-bottom: $indent-large;

    @media screen and (min-width: 1024px) {
      margin-bottom: 0;
      justify-content: flex-start;
      max-width: 100%;
    }
  }

  &__image {
    @media screen and (min-width: 1024px) {
      margin-right: 24px;
    }

    &_visa {
      height: 17px;

      @media screen and (min-width: 1024px) {
        height: 20px;
      }
    }

    &_mc {
      height: 32px;
    }

    &_mir {
      height: 17px;

      @media screen and (min-width: 1024px) {
        height: 20px;
      }
    }

    &_sk {
      height: 40px;

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 375px;

    @media screen and (min-width: 1024px) {
      justify-content: flex-end;
      max-width: 100%;
      margin-right: 70px;
    }

    @media screen and (min-width: 1360px) {
      margin-right: 0;
    }
  }

  &__link {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-decoration-line: underline;
    color: #868686;

    @media screen and (min-width: 1024px) {
      margin-left: 16px;
      font-size: 12px;
      line-height: 15px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
